.access {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  .h2_line {
    margin-top: 15px;
  }
  .container {
    padding-top: 180px;
    #nav {
      display: none;
      justify-content: space-around;
      .slick-list {
        width: 100%;
        .slick-track {
          display: flex;
          align-items: stretch;
        }
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        cursor: pointer;
        margin: 20px;
        height: auto;
        .circle {
          width: 22px;
          height: 22px;
          border-width: 1px;
          border-color: rgb(233, 240, 244);
          border-style: solid;
          border-radius: 50%;
          background-color: rgb(255, 255, 255);
          margin-top: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          .circle2 {
            border-radius: 50%;
            background: #e9f0f4;
            width: 14px;
            height: 14px;
          }
        }
      }
      .slick-current {
        .circle {
          .circle2 {
            background-image: linear-gradient(62deg, rgb(52, 182, 98) 0%, rgb(57, 206, 110) 100%);
            box-shadow: 0px 3px 13px 0px rgba(57, 206, 110, 0.52);
          }
        }
      }
    }
    .slider {
      .slick-dots {
        bottom: 100%;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        justify-content: center;
        width: 100vw;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: auto;
          height: auto;
          margin: 0 20px;
          &:before {
            content: '';
            background-size: cover;
            background-position: center center;
            width: 120px;
            min-height: 60px;
            margin-bottom: 30px;
            transition: all 0.5s;
          }
          @for $i from 1 through 8 {
            &:nth-child(#{$i}) {
              &:before {
                background-image: url("/img/slider/#{$i}.png");
              }
            }
          }
          &:nth-child(1) {
            &:before {
              width: 50px;
              height: 58px;
            }
          }
          button {
            padding: 3px;
            font-size: 0;
            width: 22px;
            height: 22px;
            position: relative;
            border-width: 1px;
            border-color: rgb(233, 240, 244);
            border-style: solid;
            border-radius: 50%;
            &:before {
              display: none;
            }
            &:after {
              content: '';
              border-radius: 50%;
              background: rgb(233, 240, 244);
              width: 100%;
              height: 100%;
              display: flex;
              z-index: 1;
            }
          }
          &.slick-active, &.slick-center {
            opacity: 1;
            button {
              &:before {
                opacity: 1;
              }
              &:after {
                background-image: linear-gradient(62deg, rgb(52, 182, 98) 0%, rgb(57, 206, 110) 100%);
                box-shadow: 0 $a3 $a13 0 rgba(57, 206, 110, 0.52);
              }
            }
          }
        }
      }
      .item {
        background-image: url(/img/slider/bg.svg);
        background-size: 100% 100%;
        width: 630px;
        height: 335px;
        margin: $a70 (-0) $a100 (-0);
        box-sizing: border-box;
        filter: drop-shadow(0 11px 35px rgba(216, 224, 230, 0.9));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0.4;
        transform: scale(0.8);
        transition: all 0.2s cubic-bezier(0, 0, 1, 1.9);
        cursor: pointer;
        box-sizing: border-box;
        padding-bottom: 30px;
        .name {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100px;
          img {
            filter: grayscale(100%);
            width: 150px;
          }
          .text {
            padding-left: 10px;
            line-height: 100%;
            width: 40%;
            p {
              font-weight: bold;
              font-size: 20px;
              padding-bottom: 10px;
              text-transform: uppercase;
            }
          }
        }
        img, picture {
          display: inline;
          padding-right: 10px;
        }
        .text {
          position: relative;
          width: 75%;
          white-space: normal;
          padding-top: 10px;
          .first {
            position: absolute;
            top: 0;
            right: 100%;
            width: 26px;
          }
          span {
            img {
              transform: rotate(180deg);
              margin-bottom: -6px;
              width: 26px;
            }
          }
        }
      }
      .slick-center {
        opacity: 1;
        transform: scale(1);
        z-index: 1;
        position: relative;
        cursor: auto;
        .name {
          img {
            filter: grayscale(0%);
          }
        }
      }
      .slick-list {
        overflow: visible;
      }
    }
  }
}

@media screen and (max-width: 1400px){
  .access .container .slider .item {
    height: 300px;
    margin-top: 40px;
    p.text {
      white-space: normal;
      width: 85%;
      text-align: justify;
      margin-left: 50px;
      margin-right: 30px;
    }
  }
}

@media screen and (max-width: 900px) {
  .access {
      .container {
        padding-top: 0;
        #nav {
          display: flex;
          .item {
            min-width: 33vw;
            margin: $m19 0;
            img {
              max-width: $m80;
              max-height: $m80;
            }
            .circle {
              width: $m16;
              height: $m16;
              box-sizing: border-box;
              margin-top: $m15;
              .circle2 {
                width: $m10;
                height: $m10;
              }
            }
          }
        }
        .slider {
          .slick-list {
            overflow: visible;
            .slick-track {
              display: flex;
              align-items: stretch;
            }
          }
          .item {
            width: 95vw;
            height: auto;
            padding-bottom: $m30;
            margin: $m19;
            opacity: 1;
            transform: scale(1);
            justify-content: flex-start;
            filter: drop-shadow(0 $m5 $m10 rgba(216,224,230,0.9));
            .name {
              padding-bottom: $m20;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-top: $m20;
              height: auto;
              img, picture {
                max-width: $m120;
                max-height: $m120;
                filter: none;
                padding: 0;
              }
              .text {
                padding: 0;
                width: 100%;
                padding-top: $m10;
                p:nth-of-type(1) {
                  font-size: $m16;
                  text-align: center !important;
                  width: 100%;
                }
                p:nth-of-type(2) {
                  font-size: $m14;
                  text-align: center !important;
                  width: 100%;
                }
              }
            }
            p.text {
              padding: 0 $m20;
              width: 100%;
              box-sizing: border-box;
              margin-left: 0;
              margin: 0;
              .first {
                position: static;
                display: none;
              }
              img {
                padding-right: $m5;
                margin-bottom: -$m5 !important;
                display: none;
              }
            }
          }
          .slick-dots {
            justify-content: flex-start;
            .slick-list {
              overflow: hidden;
              .slick-track {
                margin: $m19 0;
              }
            }
            li {
              height: $m72;
              width: 33vw;
              margin: 0;
              &:before {
                width: $m33;
                height: $m39;
              }
              button {
                width: $m16;
                height: $m16;
                padding: $m2;
              }
            }
          }
        }
      }
    }
}