@import "slick";
@import "slick-theme";
@import "all_page";
@import "jquery.fancybox.min";
@import "access";
@import "footer-wave";

main {
  color: #5d5c65;
  z-index: 3;
  position: relative;
  .header {
    background: #454545;
    background-size: cover;
    overflow: hidden;
    position: relative;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    .bg {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 1685px;
      filter: hue-rotate(60deg);
    }
    .bg2 {
      position: absolute;
      width: 615px;
      left: 0;
      top: 0;
    }
    .bg3 {
      position: absolute;
      top: 0;
      min-height: 100%;
      min-width: 100%;
      opacity: 0.1;
      img {
        min-height: 100%;
        min-width: 100%;width: auto;
      }
    }
    .digital {
      position: absolute;
      right: $a150;
      margin: auto;
      height: fit-content;
      z-index: 2;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      width: $a725;
      filter: drop-shadow($a10 $a10 $a5 rgba(0,0,0,.2));
      transform: perspective(330px) rotateY(-8deg);
      .dis_header {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transform-style: flat;
        width: $a330;
        height: $a190;
        margin: $a10;
        .border {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          &:before {
            content: '';
            height: 100.5%;
            width: $a7;
            left: 99.8%;
            bottom: 0;
            margin: auto;
            background: linear-gradient(0deg, #323131 35%, #fefdfe);
            position: absolute;
            box-sizing: border-box;
            border: none;
            transform: perspective($a300) rotateY(32deg);
          }
          &:after {
            content: '';
            width: 100.4%;
            height: $a5;
            bottom: 99.5%;
            left: $a3;
            right: 0;
            margin: auto;
            background: linear-gradient(270deg, #5a5858 60%, #fafafa);
            position: absolute;
            box-sizing: border-box;
            border: none;
            transform: perspective($a100) rotateX(50deg);
            z-index: 1;
          }
          div {
            &:nth-child(1) {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: $a5;
              background: linear-gradient(0deg, #8d8d8d 10%, #fff 40%, #8f8f8f 80%);
            }
            &:nth-child(2) {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: $a5;
              background: linear-gradient(90deg, #8d8d8d 20%, #878787 50%, #8f8f8f 80%);
            }
            &:nth-child(3) {
              position: absolute;
              right: 0;
              top: 0;
              height: 100%;
              width: $a5;
              background: linear-gradient(0deg, #8d8d8d 30%, #fff 60%, #8f8f8f 80%);
            }
            &:nth-child(4) {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: $a5;
              background: linear-gradient(90deg, #8d8d8d 20%, #8f8e8f 50%, #8f8f8f 80%);
            }
          }
        }
        .bg_dis {
          position: absolute;
          background-size: cover;
          overflow: hidden;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          .hover {
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.7);
            bottom: 100%;
            transition: all 0.5s;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .title {
              font-weight: 800;
              font-size: $a20;
              letter-spacing: 0.1em;
              text-align: center;
            }
            .button1, .button2 {
              width: 40%;
              padding: 0;
              min-width: 0;
              height: $a30;
              margin-top: $a20;
              font-size: $a12;
              cursor: pointer;
              &:before {
                display: none;
              }
              &:hover {
                color: #ffffff;
              }
            }
          }
        }
        &:nth-child(1) {
          .border {
            &:after {
              display: none;
            }
            &:before {
              height: 99.5%;
            }
          }
          .bg_dis {
            /*width: 106%;
            height: 92%;
            right: 0;
            left: auto;
            top: $a8;*/
            background-image: url("/img/app/1.jpg");
          }
        }
        &:nth-child(2) {
          .border {
            &:after {
              display: none;
            }
            &:before {
              height: 99.5%;
            }
          }
          .bg_dis {
            /*width: 102%;
            right: $a4;
            height: 91%;
            top: $a10;*/
            background-image: url("/img/app/4.jpg");
            /*transform: perspective(44.27083vw) rotateY(-22deg) rotateX(0deg) rotateZ(-1deg);*/
          }
        }
        &:nth-child(3) {
          .bg_dis {
            /*transform: perspective(44.27083vw) rotateY(-23deg) rotateX(-10deg) rotateZ(5deg);*/
            /*width: 106%;
            height: 85%;
            right: $a2;
            top: $a10;*/
            background-image: url("/img/app/2.jpg");
          }
        }
        &:nth-child(4) {
          .bg_dis {
            /*transform: perspective(51.27083vw) rotateY(-27deg) rotateX(-9deg) rotateZ(4.5deg);*/
            /*width: 105%;
            height: 83%;
            right: 0;
            top: $a15;*/
            background-image: url("/img/wating_room.png");
          }
        }
        &:hover {
          .bg_dis {
            .hover {
              bottom: 0;
            }
          }
        }
      }
    }
    .container {
      height: 100%;
      justify-content: center;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 80px;
      h1 {
        text-transform: uppercase;
        font-size: 52px;
        align-self: flex-start;
        margin-bottom: 30px;
        span {
          display: flex;
          align-items: center;
          svg {
            overflow: visible;
          }
        }
      }
      .play {
        width: 82px;
        height: 82px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        z-index: 1;
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.21, 2.85);
        button {
          display: none;
        }
        .video {
          display: none;
        }
        .circle {
          border-radius: 50%;
          background-color: rgb(255, 255, 255);
          width: 70%;
          height: 70%;
          display: flex;
          align-items: center;
          justify-content: center;
          will-change: auto;
          &:after, &:before {
            content: '';
            opacity: 0;
            background: rgba(255,255,255, 0.35);
            border-radius: 100%;
            width: 100%;
            height: 100%;
            position: absolute;
            animation: buttonPulse2 cubic-bezier(0, 0.54, 0.53, 1) 3s;
            animation-fill-mode: forwards;
            animation-delay: 0s;
            animation-iteration-count: infinite;
            z-index: -1;
          }
          &:before {
            animation-delay: 1.5s;
          }
          svg {
            width: 35%;
            height: 39%;
          }
        }
        &:active {
          .circle {
            box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.5);
          }
        }
        &:hover {
          transform: scale(1.1);
        }
      }
      .text {
        font-family: cursive;
        font-weight: 500;
        font-size: 20px;
        margin-top: 60px;
        letter-spacing: 0.1em;
        position: relative;
        right: 220px;
        img {
          transform: rotateY(180deg) rotateZ(110deg);
          position: absolute;
          bottom: 50%;
          left: calc(100% + #{5px});
          width: 45px;
        }
      }
      .scroll {
        position: absolute;
        bottom: $a40;
        left: 0;
        right: 0;
        margin: auto;
        width: 30px;
        a {
          display: inline-block;
          width: 100%;
          text-align: center;
          border-radius: 50%;
          animation: nudgeMouse 2.4s cubic-bezier(0.250,0.460,0.450,0.940) infinite;
          &:before {
            position: absolute;
            content: '';
            display: block;
            left: 50%;
            margin-left: -1px;
            top: 22px;
            background-color: rgba(255,255,255,0.7);
            width: 2px;
            height: 6px;
            border-radius: 10px;
            transition: background-color .55s cubic-bezier(.5,.1,.07,1);
            animation: trackBallSlide 2.4s cubic-bezier(0.000,0.000,0.725,1.000) infinite;
          }
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            display: block;
            width: 30px;
            height: 45px;
            margin-left: -15px;
            border: 2px solid rgba(255,255,255,0.7);
            border-radius: 30px;
            box-sizing: border-box;
            z-index: 62;
          }
          svg {
            path {
              fill: transparent;
              stroke-dashoffset: 120;
              stroke-dasharray: 120;
              -webkit-animation: mouse-scroll-btn-roll-out .55s cubic-bezier(.5,.1,.07,1);
              animation: mouse-scroll-btn-roll-out .55s cubic-bezier(.5,.1,.07,1);
            }
          }
        }
        &:hover {
          a {
            &:before {
              background-color: rgba(255,255,255,1);
            }
            svg {
              path {
                stroke-dashoffset: 0;
                animation: mouse-scroll-btn-roll-over .55s cubic-bezier(.5,.1,.07,1);
              }
            }
          }
        }
      }
    }
  }
  .with_us {
    padding: $a120 0 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    position: relative;
    .container {
      padding-top: 100px;
      padding-bottom: $a54;
      display: flex;
      justify-content: center;
      .item {
        background: url(/img/with_us/bg.svg);
        background-size: 100% 100%;
        filter: drop-shadow(0px 5px 32px rgba(217, 216, 229, 0.9));
        width: 33%;
        border-radius: 70px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 40px 50px;
        margin: 0 7px;
        img {
            max-width: 100%;
        }
        .img {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 100%;
          z-index: 1;
          margin-top: -40%;
          div {
            width: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            .circle {
              position: static;
            }
          }
          .circle {
            width: 90%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
          }
          .security {
            position: absolute;
            width: 97%;
            bottom: 15px;

          }
          .guaranteed {
            position: absolute;
            width: 95%;
            bottom: 4.4%;
            left: 11.8%;
          }
          .stroke {
            width: 100%;
            z-index: -1;
            border-radius: 50%;
          }
          .planet {
            position: absolute;
            left: -18%;
            top: 14.4%;
            width: 54%;
          }
          .people {
            position: absolute;
            width: 100%;
            bottom: 4%;
            left: 3%;
          }
          .dashed {
            position: absolute;
            top: 2%;
            right: 2%;
            width: 54%;
            z-index: -2;
          }
          .display {
            position: absolute;
            width: 18%;
            left: 24px;
            bottom: 73px;
            opacity: 0;
          }
          .star {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 80%;
          }
          .clock {
            position: absolute;
            width: 18%;
            top: 9%;
            left: 9%;
          }
          .phone {
            position: absolute;
            top: 0;
            width: 15%;
            left: 6%;
            bottom: 4%;
            margin: auto;
          }
          .plansh {
            width: 18%;
            bottom: 13%;
            left: 7%;
            position: absolute;
          }
          .many {
            position: absolute;
            width: 18%;
            right: 11%;
            top: 11%;
          }
          .cap {
            position: absolute;
            top: 0;
            width: 18%;
            right: 7%;
            bottom: 10%;
            margin: auto;
          }
          .notebook {
            width: 25%;
            right: 4%;
            bottom: 5%;
            position: absolute;
          }
        }
        h3 {
          min-height: 100px;
          padding-top: 10px;
          display: flex;
          align-items: center;
        }
        p {
          padding-top: 10px;
        }
      }
    }
  }
  .profit {
    padding-bottom: $a100;
    .container {
      display: flex;
      align-items: center;
      .left {
        min-width: 40%;
        h2 {
          text-align: left;
        }
        .h2_line {
          margin: 30px 0;
        }
        p {
          padding-bottom: 50px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        .h_dis {
          height: 450px;
          width: 270px;
          border: 3px solid #000000;
          position: absolute;
          left: 200px;
          top: 0;
          bottom: 0;
          margin: auto;
          transition: all 0.5s;
          transform: perspective(300px) rotateY(-9deg);
          &:before {
            content: '';
            height: 100.4%;
            width: 11px;
            left: 100.4%;
            bottom: 0;
            top: 0;
            margin: auto;
            background: linear-gradient(180deg, #969292, #000);
            position: absolute;
            box-sizing: border-box;
            border: none;
            transform: perspective(300px) rotateY(32deg);
            z-index: -1;
          }
          .slider3 {
            position: absolute;
            right: -0.2%;
            top: -0.2%;
            width: 100.4%;
            height: 100.4%;
            overflow: hidden;
            .slick-list {
              height: 100%;
              .slick-track {
                height: 100%;
              }
            }
            div {
              height: 100%;
              picture {
                  height: 100%;
              }
              img {
                height: 100%;
                width: auto;
                max-width: inherit;
              }
            }
          }
        }
        .bg {
          width: 1080px;
        }
        .bg2 {
          position: absolute;
          left: 5%;
          top: 60%;
          transform: translateY(-50%);
          width: 100%;
          z-index: -1;
        }
        .a1 {
          position: absolute;
          width: 7.408%;
          right: (340%/10.8);
          top: (90%/5.38);
        }
        .a2 {
          @extend .a1;
          right: (198%/10.8);
          top: (112%/5.38);
        }
        .a3 {
          @extend .a1;
          top: (170%/5.38);
          right: (100%/10.8);
        }
        .a4 {
          @extend .a1;
          right: (465%/10.8);
          top: (190%/5.38);
        }
        .a5 {
          @extend .a1;
          top: (200%/5.38);
          right: (244%/10.8);
        }
        .a6 {
          @extend .a1;
          right: (310%/10.8);
          top: (280%/5.38);
        }
        .a7{
          @extend .a1;
          top: (290%/5.38);
          right: (490%/10.8);
        }
        .a8 {
          @extend .a1;
          right: (410%/10.8);
          top: (360%/5.38);
        }
        .a9 {
          @extend .a1;
          right: (210%/10.8);
          top: auto;
          bottom: (60%/5.38);
        }
      }
    }
  }
  .app {
    padding-bottom: $a100;
    position: relative;
    z-index: 1;
    .container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: $a669;
      position: static;
      .left {
        width: 40%;
        h2 {
          text-align: left;
        }
        .h2_line {
          margin: 30px 0;
        }
        p {
          padding-bottom: 50px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        position: absolute;
        perspective: $a550;
        left: 0;
        top: 0;
        .bg {
          width: $a1080;
        }
        .bg2, .bg3 {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          z-index: 1;
        }
        .screen {
          position: absolute;
          display: flex;
          flex-flow: row wrap;
          width: 31.69375vw;
          height: 23.84583vw;
          top: $a44;
          right: $a260;
          transition: all 0.5s;
          transform: perspective($a850) rotateY(9deg) rotateX(0deg) rotateZ(0deg);
          .display {
            width: (100%/3);
            height: (100%/3);
            box-sizing: border-box;
            border: 1px solid #000000;
            background-size: cover;
            z-index: 1;
            animation-timing-function: linear!important;
            animation-play-state: paused;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            position: relative;
            video {
              width: 100%;
              height: 100%;
              animation-play-state: paused;
              object-fit: cover;
            }
            .bg_logo {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              width: 80%;
            }
            &:hover {
              video, .bg_logo {
                animation-fill-mode: none;
              }
            }
            &:nth-child(1), &:nth-child(4), &:nth-child(7) {
              position: relative;
              &:before {
                content: '';
                height: 99%;
                bottom: 0;
                top: 0;
                margin: auto;
                position: absolute;
                right: 100%;
                width: $a10;
                transform: perspective(100px) rotateY(-40deg);
              }
            }
            &:nth-child(7) {
              &:before {
                background: linear-gradient(180deg, #313030, #000);
                height: 97.6%;
              }
            }
            &:nth-child(4) {
              &:before {
                background: linear-gradient(180deg, #646262, #313030);
              }
            }
            &:nth-child(1) {
              &:before {
                background: linear-gradient(180deg, #969292, #646262);
              }
            }
          }
          .background {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            animation-duration: 0s;
            animation-delay: 1s;
            opacity: 0;
            animation-fill-mode: forwards;
            animation-play-state: paused;
            video {
              object-fit: cover;
              height: 100%;
            }
          }
          .face {
            height: 99%;
            bottom: $a1;
            margin: auto;
            top: 0;
            right: 99.62%;
            width: $a11;
            background: linear-gradient(180deg, #969292, #000);
            position: absolute;
            box-sizing: border-box;
            border: none;
            transform: perspective($a300) rotateY(-32deg);
            animation-play-state: paused;
            animation-duration: .5s;
            animation-delay: 1s;
            opacity: 0;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
  .article {
    display: flex;
    flex-direction: column;
    align-items: center;
    .container {
      display: flex;
      justify-content: space-between;
      transform-style: preserve-3d;
      align-items: stretch;
      a {
        transition: all 0.5s;
        width: 32.5%;
        will-change: transform;
        &:hover {
          transform: scale(1.1);
        }
        &:nth-of-type(1) {
          order: 1;
          &:hover {
            &~a {
              &~a {
                transform: translate(30px);
              }
            }
          }
        }
        &:nth-of-type(2) {
          order: 3;

          &:hover {
            &~a {
              transform: translate(-30px);
            }
          }
        }
        &:nth-of-type(3) {
          order: 2;
        }
      }
      .item {
        background: url(/img/article/bg.svg);
        background-size: 100% 100%;
        filter: drop-shadow(0px 5px 32px rgba(217, 216, 229, 0.9));
        position: relative;
        padding: 22px 0 40px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #32313c;
        transition: all 0.5s;
        height: 100%;
        box-sizing: border-box;
        .img {
          width: 87%;
          height: 200px;
          margin-bottom: 25px;
          border-radius: 35px;
          overflow: hidden;
          display: flex;
          align-items: center;
          picture {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        .name {
          font-weight: bold;
          font-size: 23px;
          width: 75%;
          height: 108px;
        }
        .text {
          width: 70%;
          padding-top: 20px;
          white-space: normal;
        }
      }
    }
    .button1 {
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 1450px) {
  main {
    .header {
      .digital {
        right: $a50;
        width: $a865;
        filter: drop-shadow($a10 $a10 $a5 rgba(0,0,0,.2));
        transform: perspective(330px) rotateY(-8deg);
        .dis_header {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          transform-style: flat;
          width: $a400;
          height: $a220;
          margin: $a10;
          .border {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            &:before {
              content: '';
              height: 100.5%;
              width: $a7;
              left: 99.8%;
              bottom: 0;
              margin: auto;
              background: linear-gradient(0deg, #323131 35%, #fefdfe);
              position: absolute;
              box-sizing: border-box;
              border: none;
              transform: perspective($a300) rotateY(32deg);
            }
            &:after {
              content: '';
              width: 100.4%;
              height: $a5;
              bottom: 99.5%;
              left: $a3;
              right: 0;
              margin: auto;
              background: linear-gradient(270deg, #5a5858 60%, #fafafa);
              position: absolute;
              box-sizing: border-box;
              border: none;
              transform: perspective($a100) rotateX(50deg);
              z-index: 1;
            }
            div {
              &:nth-child(1) {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: $a5;
                background: linear-gradient(0deg, #8d8d8d 10%, #fff 40%, #8f8f8f 80%);
              }
              &:nth-child(2) {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: $a5;
                background: linear-gradient(90deg, #8d8d8d 20%, #878787 50%, #8f8f8f 80%);
              }
              &:nth-child(3) {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: $a5;
                background: linear-gradient(0deg, #8d8d8d 30%, #fff 60%, #8f8f8f 80%);
              }
              &:nth-child(4) {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: $a5;
                background: linear-gradient(90deg, #8d8d8d 20%, #8f8e8f 50%, #8f8f8f 80%);
              }
            }
          }
          .bg_dis {
            position: absolute;
            background-size: cover;
            overflow: hidden;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .hover {
              width: 100%;
              height: 100%;
              background: rgba(0,0,0,0.7);
              bottom: 100%;
              transition: all 0.5s;
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              .title {
                font-weight: 800;
                font-size: $a20;
                letter-spacing: 0.1em;
              }
              .button1, .button2 {
                width: 40%;
                height: $a30;
                margin-top: $a20;
                font-size: $a12;
                cursor: pointer;
                &:before {
                  display: none;
                }
                &:hover {
                  color: #ffffff;
                }
              }
            }
          }
          &:nth-child(1) {
            .border {
              &:after {
                display: none;
              }
              &:before {
                height: 99.5%;
              }
            }
            .bg_dis {
              /*width: 106%;
              height: 92%;
              right: 0;
              left: auto;
              top: $a8;*/
              background-image: url("/img/app/1.jpg");
            }
          }
          &:nth-child(2) {
            .border {
              &:after {
                display: none;
              }
              &:before {
                height: 99.5%;
              }
            }
            .bg_dis {
              /*width: 102%;
              right: $a4;
              height: 91%;
              top: $a10;*/
              background-image: url("/img/app/4.jpg");
              /*transform: perspective(44.27083vw) rotateY(-22deg) rotateX(0deg) rotateZ(-1deg);*/
            }
          }
          &:nth-child(3) {
            .bg_dis {
              /*transform: perspective(44.27083vw) rotateY(-23deg) rotateX(-10deg) rotateZ(5deg);*/
              /*width: 106%;
              height: 85%;
              right: $a2;
              top: $a10;*/
              background-image: url("/img/app/2.jpg");
            }
          }
          &:nth-child(4) {
            .bg_dis {
              /*transform: perspective(51.27083vw) rotateY(-27deg) rotateX(-9deg) rotateZ(4.5deg);*/
              /*width: 105%;
              height: 83%;
              right: 0;
              top: $a15;*/
              background-image: url("/img/app/3.jpg");
            }
          }
          &:hover {
            .bg_dis {
              .hover {
                bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px){
  main {
    .with_us {
      .container {
        .item {
          padding: 35px 30px;
          .img {
            width: 70%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    .article {
      .container {
        a {
          width: 31.5%;
        }
        .item {
          .img {
            width: 80%;
          }
          .name, .text {
            width: 80%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1270px) {
  main {
    .header {
      .digital {
        right: 20px;
      }
      .container {
        h1 {
          font-size: 45px;
        }
        .play {
          position: absolute;
          top: 40px;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
        .text {
          margin-bottom: -30px;
        }
      }
    }
    .article {
      .container {
        .item {
          .name,  .text {
            width: 80%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobi_start) {
  .fancybox-slide {
    padding: $m10;
    .fancybox-content {
      border-radius: $m30;
      overflow: hidden;
    }
  }
  main {
    overflow: hidden;
    .header {
      height: auto;
      .bg3 {
        height: 100%;
        width: auto;
        img {
          height: 100%;
          width: auto;
        }
      }
      .bg {
        left: 50%;
        right: auto;
        width: 100%;
      }
      .bg2 {
        width: 100%;
      }
      .digital {
        display: none;
      }
      .container {
        z-index: 1;
        padding-top: $m60;
        padding-bottom: $m30;
        .left {
          width: 100%;
        }
        h1 {
          font-size: $m23 !important;
          width: 100%;
          margin-bottom: 0;
          span {
            height: $m32;
            overflow: hidden;
            width: auto;
            svg {
              height: 100%;
            }
          }
        }
        p.text {
          font-size: $m14;
          padding-top: $m10;
          right: 0;
          text-align: center !important;
          margin: 0;
          img {
            width: $m30;
            bottom: $m8;
            display: none;
          }
        }
        .play {
          width: 100%;
          height: $m170;
          border-radius: $m30;
          background: url(/img/mobi/video.jpg) no-repeat center center / cover;
          margin-top: $m25;
          z-index: auto;
          box-shadow: inset 0 5px 10px rgba(0,0,0,0.5);
          overflow: hidden;
          top: 0;
          left: 0;
          position: relative;
          .circle {
            border-radius: 50% / 10%;
            background: red;
            width: $m47;
            height: $m35;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 1;
            display: block;
            overflow: visible;
            display: flex;
            align-items: center;
            justify-content: center;
            &:before {
              background: red;
              border-radius: 5% / 50%;
              bottom: 0;
              content: "";
              left: -5%;
              position: absolute;
              right: -5%;
              width: 110%;
              top: 0;
              margin: auto;
              height: 80%;
              animation: none;
              opacity: 1;
            }
            &:after {
              border-style: solid;
              border-width: $m7 0 $m7 $m13;
              border-color: transparent transparent transparent rgba(255, 255, 255, 1);
              content: ' ';
              height: 0;
              width: 0;
              top: 0;
              bottom: 0;
              left: $m3;
              right: 0;
              margin: auto;
              position: absolute;
              animation: none;
            }
            svg {
              polygon {
                fill: #fff;
              }
            }
          }
          &:hover {
            transform: none;
          }
          .video {
            display: block;
            width: 101%;
            height: 101%;
          }
        }
        .scroll {
          display: none;
        }
      }
    }
    .with_us {
      padding-bottom: $m45;
      padding-top: $m30;
      .container {
        padding-top: $m70;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .slick-list {
          overflow: visible;
          width: 100%;
          box-sizing: border-box;
          .slick-track {
            display: flex;
            align-items: stretch;
          }
        }
        .item {
          margin: $m30 $m30 0 $m30;
          width: 95vw;
          height: auto;
          padding: $m50 0 0 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: none;
          h3 {
            min-height: $m48;
            margin-top: $m20;
            height: auto;
            white-space: normal;
            text-align: center;
          }
          p {
            font-size: $m12;
            padding: 0;
            margin-top: $m10;
          }
          .img {
            width: $m160;
            .dashed {
              width: $m80;
            }
            .guaranteed {
              width: 96%;
              bottom: $m8;
              right: -$m11;
              left: auto;
            }
            .security {
              width: 97%;
              bottom: $m13;
            }
          }
        }
      }
    }
    .profit {
      padding-bottom: $m30;
      .container {
        flex-direction: column-reverse;
        .right {
          margin-bottom: $m110;
          .bg {
            width: 100vw;
          }
          &>img:not(.bg) {
            display: none;
          }
          .h_dis {
            width: 45%;
            height: $m230;
            left: 0;
            right: 0;
            margin: auto;
            transform: none;
            bottom: auto;
            &:before {
                display: none;
            }
          }
        }
        .left {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          h2 {
            padding: 0;
          }
          p {
            padding-bottom: $m30;
          }
        }
      }
    }
    .app {
      padding-bottom: $m30;
      .container {
        flex-direction: column;
        height: auto;
        .right {
          perspective: none;
          padding-bottom: $m20;
          position: static;
          .bg {
            width: 100vw;
          }
          .bg2 {
            right: -$m20;
            display: none;
          }
          .screen {
            width: 95%;
            height: $m190;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            transform: perspective($m300) rotateY(0deg) rotateX(0deg) rotateZ(0deg);
            .face {
              display: none;
            }
            .display {
              background: url('/img/logo_color.png') no-repeat center center / 80% auto, #32313c;
              background-position: center center !important;
              video {
                display: none;
              }
                &:before {
                    display: none;
                }
              &:hover {
                video, .bg_logo {
                  animation-fill-mode: forwards;
                }
              }
            }
            .slider2 {
              div {
                img {
                  will-change: transform;
                  min-width: 100%;
                  min-height: 100%;
                }
              }
            }
          }
        }
        .left {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          h2 {
            padding: 0;
          }
          p {
            padding-bottom: $m30;
          }
        }
      }
    }
    .article {
      padding-top: $m20;
      padding-bottom: 0;
      .h2_under {
        padding-bottom: $m30;
        padding-top: 0;
      }
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        .slick-list {
          overflow: visible;
          width: 100%;
          box-sizing: border-box;
        }
        a {
          width: auto !important;
          &:hover {
              transform: none;
          }
        }
        .item {
          margin: 0 $m30;
          width: 95vw;
          padding: $m15;
          box-sizing: border-box;
          .img {
            width: 100%;
            padding-bottom: $m10;
          }
          p {
            width: 90% !important;
            font-size: $m12;
            text-align-last: center;
          }
          .name {
            font-size: $m20;
            padding-bottom: $m5;
            text-align: center !important;
          }
        }
      }
      .button1 {
        margin-top: 0;
      }
    }
  }
}
